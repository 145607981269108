import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/trendanalysis.png";
import emotion from "../../Assets/Projects/text.png";
import editor from "../../Assets/Projects/credit card.png";
import chatify from "../../Assets/Projects/Nachos.jpg";
import suicide from "../../Assets/Projects/gold price.png";
import bitsOfCode from "../../Assets/Projects/Vitavisuals.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Multiplex Chain Application"
              description="Developed an application where it allows to book a desired movie at desired location."
              ghLink="https://github.com/varunpapishetty/Nachos"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Vita Visuals"
              description="Developed a healthcare solution to simplify doctor-patient interactions with medical documents using AI."
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Credit Card Filter"
              description="Objective of this project is on the validation and identification of credit card types from various data sources. Our primary goal is to process credit card data, ensuring the validity of credit card numbers and correctly categorizing them into types like Visa, MasterCard, American Express, and Discover"
              ghLink="https://github.com/gopinathsjsu/individual-project-varunpapishetty.git"
                           
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Trend Analysis in grocery store using machine learning"
              description="Made machine learning models which made owners to analyse on the products they are selling and also have a track on customers which helped them in maintaining stock"
              
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Gold Price Prediction"
              description="Used Random forest regression model for predicting gold price which helps for future investements"
              
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Collaborative Text Classification and Editing Platform"
              description="Designed and developed a text classification project where multiple devices can simultaneously work on a single document without the internet, enhancing real-time collaboration in workplaces."
              
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
